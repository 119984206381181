import { formatDate, isTrialStillValid } from "@lib/iso-utils";
import { useGetSubscriptionDetails } from "./useGetSubscriptionDetails";

/**
 * Custom hook that determines whether the user is eligible for a free trial offer.
 *
 * The logic follows these steps:
 * 1. If subscription details are not available, return `undefined`.
 * 2. If the user has an active subscription in trial mode, return `{ isValid: true, trialEndDate: null }`
 *    to ensure all products are offered with a discount.
 * 3. If the user doesn't have a subscription, check if a free trial is available
 *    (based on `NEXT_PUBLIC_STRIPE_TRIAL_END_DATE`).
 * 4. If the trial is valid, return `{ isValid: true, trialEndDate: "March 1st, 2025" }`.
 * 5. If none of the conditions are met, return `{ isValid: false, trialEndDate: null }`.
 */
export const useGetOfferFreeTrial = () => {
  const { hasSubscription, subscriptionStatus, trialEnd } =
    useGetSubscriptionDetails();

  // We don't have information about the subscription yet, so return `undefined`
  if (hasSubscription === undefined) return undefined;

  // If the user has an active subscription in trial mode, return true with no trial end date
  if (
    hasSubscription &&
    subscriptionStatus &&
    subscriptionStatus === "trialing"
  ) {
    return { isValid: true, trialEndDate: trialEnd };
  }

  // If the user does not have a subscription, check if a free trial is still valid.
  if (hasSubscription === false) {
    const trialEndDateStr = process.env.NEXT_PUBLIC_STRIPE_TRIAL_END_DATE;

    if (!trialEndDateStr) {
      return { isValid: false, trialEndDate: null };
    }

    const trialEndDate = new Date(trialEndDateStr);

    if (isNaN(trialEndDate.getTime())) {
      return { isValid: false, trialEndDate: null };
    }

    const isValid = isTrialStillValid();
    return {
      isValid,
      trialEndDate: isValid ? formatDate(trialEndDate) : null,
    };
  }

  // Default case, return false
  return { isValid: false, trialEndDate: null };
};
