import { useCreateOrganization } from "@custom-hooks/organizations/hooks/useCreateOrganization";
import { GenericDialogWithForm, GenericInput, MessageType } from "@tw-components/ui/iso";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import * as Yup from "yup";

/**
 * Props for the CreateOrganizationDialog component.
 *
 * @property {boolean} isOpen - Whether the dialog is open.
 * @property {() => void} onClose - Function to call when the dialog is closed.
 */
type CreateOrganizationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * A dialog component for creating an organization
 *
 * @param {CreateOrganizationDialogProps} props - The props for the component.
 *
 * @returns {JSX.Element} The JSX element for the CreateOrganizationDialog component.
 */
export function CreateOrganizationDialog({
  isOpen,
  onClose
}: CreateOrganizationDialogProps): JSX.Element {
  const {
    createOrganization
  } = useCreateOrganization({});
  const router = useRouter();
  const posthog = usePostHog();
  const validationSchema = Yup.object().shape({
    organizationName: Yup.string().required("Workspace name is required.").matches(/^[a-zA-Z0-9 _.\-@]+$/, "Workspace name must be composed only of letters, numbers, spaces, underscores, dashes, periods, or at symbols.").max(32, "Workspace name must not exceed 32 characters.")
  });
  return <GenericDialogWithForm<{
    organizationName: string;
  }> initialValues={{
    organizationName: ""
  }} validationSchema={validationSchema} onSubmit={async (values, {
    setStatus
  }) => {
    const createOrganizationResult = await createOrganization({
      name: values.organizationName
    });
    if (createOrganizationResult.success) {
      onClose();
      const organizationId = createOrganizationResult.data.value.organization_id;
      posthog.capture("organization_created", {
        organization_id: organizationId
      });
      router.push(`/organizations/${organizationId}/projects`);
    } else {
      setStatus({
        type: MessageType.Error,
        title: "Failed to create workspace",
        description: "An error occurred while creating your workspace. Please try again later. If the issue persists, contact us for assistance."
      });
    }
  }} dialogProps={formikProps => {
    const {
      values,
      isSubmitting,
      errors,
      setStatus,
      handleChange,
      handleBlur
    } = formikProps;
    return {
      onClose: onClose,
      open: isOpen,
      title: "Create Workspace",
      size: "lg",
      buttonLayoutType: "row",
      submitButtonProps: {
        size: "medium",
        variant: "primary",
        label: "Create",
        className: "tw-w-[6.625rem]"
      },
      body: <div className="tw-flex tw-flex-col tw-gap-4">
              <GenericInput autoFocus={true} sizeVar="medium" label="Workspace name" name="organizationName" value={values.organizationName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(e);
          setStatus(undefined);
        }} onBlur={handleBlur} errors={errors} disabled={isSubmitting} />
            </div>
    };
  }} data-sentry-element="GenericDialogWithForm" data-sentry-component="CreateOrganizationDialog" data-sentry-source-file="CreateOrganizationDialog.tsx" />;
}