//
// useGetSidebarTree.tsx
//

/**
 * generates the sidebar tree structure based on the current context (project or no project).
 * If the project ID is not defined in the URL, it means the user has not selected a project.
 * If the project ID is present, the user is navigating within a specific project.
 * The sidebar tree structure is built accordingly.
 */

import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useGetCurrentProjectId, useGetProject } from "@custom-hooks/projects";
import { useUserPermissions } from "@custom-hooks/role-based-access-control";
import { useGetSqlitecloudCompanyUser } from "@custom-hooks/useApi";
import BackupsIcon from "@layouts/svg-icon/backups-icon.svg";
import BillingIcon from "@layouts/svg-icon/billing-icon.svg";
import ConsoleIcon from "@layouts/svg-icon/console-icon.svg";
import DatabaseIcon from "@layouts/svg-icon/database-icon.svg";
import EdgeFunctionsIcon from "@layouts/svg-icon/edge-functions-icon.svg";
import MembersIcon from "@layouts/svg-icon/members-icon.svg";
import NodesIcon from "@layouts/svg-icon/nodes-icon.svg";
import ProjectsIcon from "@layouts/svg-icon/projects-icon.svg";
import QueryAnalyzerIcon from "@layouts/svg-icon/query-analyzer-icon.svg";
import SettingsIcon from "@layouts/svg-icon/settings-icon.svg";
import WebhooksIcon from "@layouts/svg-icon/webhooks-icon.svg";
import WebliteIcon from "@layouts/svg-icon/weblite-icon.svg";

// Define the structure of a SidebarItem with optional children
export type SidebarItemType = {
  href?: string;
  label: string;
  icon?: React.ReactNode;
  children?: SidebarItemType[];
  experimental?: boolean;
  disabled?: boolean;
  slug: string;
} | null;

// Define the structure of a SidebarTree object
export interface SidebarTree {
  title: string;
  pages: SidebarItemType[];
}

// Generates the sidebar tree based on whether a project is selected or not
const useGetSidebarTree = (): SidebarTree | undefined => {
  const projectId = useGetCurrentProjectId();
  const organizationId = useGetCurrentOrganizationId();
  const {
    data: project
  } = useGetProject(projectId || "");
  const isSqlitecloudCompanyUser = useGetSqlitecloudCompanyUser();
  const permissions = useUserPermissions();
  if (project && organizationId) {
    const nodes_count = project.nodes_count;
    // Build the sidebar structure for a project-specific view
    const projectId = project.id;
    const pages: SidebarItemType[] = [{
      href: `/organizations/${organizationId}/projects/${projectId}/databases`,
      slug: "databases",
      label: "Databases",
      icon: <DatabaseIcon />,
      disabled: !nodes_count
    }, {
      href: `/organizations/${organizationId}/projects/${projectId}/nodes`,
      slug: "nodes",
      label: "Nodes",
      icon: <NodesIcon />
    }, {
      href: `/organizations/${organizationId}/projects/${projectId}/studio`,
      slug: "studio",
      label: "Studio",
      icon: <ConsoleIcon />,
      disabled: !nodes_count
    }, isSqlitecloudCompanyUser ? {
      href: `/organizations/${organizationId}/projects/${projectId}/console`,
      slug: "console",
      label: "Console",
      icon: <ConsoleIcon />,
      experimental: true,
      disabled: !nodes_count
    } : null, {
      href: `/organizations/${organizationId}/projects/${projectId}/edge-functions`,
      slug: "edge-functions",
      label: "Edge Functions",
      icon: <EdgeFunctionsIcon />,
      disabled: !nodes_count
    }, {
      href: `/organizations/${organizationId}/projects/${projectId}/webhooks`,
      slug: "webhooks",
      label: "Webhooks",
      icon: <WebhooksIcon />,
      disabled: !nodes_count
    }, {
      href: `/organizations/${organizationId}/projects/${projectId}/backups`,
      slug: "backups",
      label: "Backups",
      icon: <BackupsIcon />,
      disabled: !nodes_count
    },
    /**
    {
      href: `/organizations/${organizationId}/projects/${projectId}/logs`,
      slug: "logs",
      label: "Logs",
      icon: <LogsIcon />,
      disabled: !nodes_count,
    },
     */
    {
      href: `/organizations/${organizationId}/projects/${projectId}/weblite`,
      slug: "weblite",
      label: "Weblite",
      icon: <WebliteIcon />,
      disabled: !nodes_count
    }, {
      href: `/organizations/${organizationId}/projects/${projectId}/query-analyzer`,
      slug: "query-analyzer",
      label: "Query Analyzer",
      icon: <QueryAnalyzerIcon />,
      disabled: !nodes_count
    }, {
      slug: "settings",
      label: "Settings",
      icon: <SettingsIcon />,
      disabled: !nodes_count,
      children: [{
        href: `/organizations/${organizationId}/projects/${projectId}/settings/users`,
        slug: "users",
        label: "Users",
        disabled: !nodes_count
      }, {
        href: `/organizations/${organizationId}/projects/${projectId}/settings/roles`,
        slug: "roles",
        label: "Roles",
        disabled: !nodes_count
      }, {
        href: `/organizations/${organizationId}/projects/${projectId}/settings/api-keys`,
        slug: "api-keys",
        label: "API Keys",
        disabled: !nodes_count
      }, isSqlitecloudCompanyUser ? {
        href: `/organizations/${organizationId}/projects/${projectId}/settings/environment-variables`,
        slug: "environment-variables",
        label: "Env Variables",
        experimental: true
      } : null,
      // These advanced settings are only available for Sqlitecloud company users
      isSqlitecloudCompanyUser ? {
        href: `/organizations/${organizationId}/projects/${projectId}/settings/advanced-settings`,
        slug: "advanced-settings",
        label: "Advanced",
        experimental: true,
        disabled: !nodes_count
      } : null, isSqlitecloudCompanyUser ? {
        href: `/organizations/${organizationId}/projects/${projectId}/settings/ip-restriction`,
        slug: "ip-restriction",
        label: "IP Restriction",
        experimental: true
      } : null, isSqlitecloudCompanyUser ? {
        href: `/organizations/${organizationId}/projects/${projectId}/settings/plugins`,
        slug: "plugins",
        label: "Plugins",
        experimental: true,
        disabled: !nodes_count
      } : null, isSqlitecloudCompanyUser ? {
        href: `/organizations/${organizationId}/projects/${projectId}/settings/commands`,
        slug: "commands",
        label: "Commands",
        experimental: true,
        disabled: !nodes_count
      } : null].filter(Boolean) as SidebarItemType[] // Filter out any null values
    }];
    // Return the sidebar object with the project title and pages
    return {
      title: project.name,
      pages: pages
    };
  } else if (organizationId) {
    // Build the sidebar structure for the organization-level view (no specific project)
    const pages: SidebarItemType[] = [{
      href: `/organizations/${organizationId}/projects`,
      slug: "",
      label: "Projects",
      icon: <ProjectsIcon />
    }, permissions.canViewBillingDetails ? {
      href: `/organizations/${organizationId}/billing`,
      slug: "billing",
      label: "Billing",
      icon: <BillingIcon />
    } : null, permissions.canViewMembers ? {
      href: `/organizations/${organizationId}/members`,
      slug: "members",
      label: "Members",
      icon: <MembersIcon />
    } : null];
    // Return the sidebar object without a title (no project selected)
    return {
      title: "",
      pages: pages
    };
  }
};
export { useGetSidebarTree };