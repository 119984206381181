//
// UpgradeDialog.tsx - Upgrade Dialog Component
//

import { useCreateSubscription, useGetSubscriptionDetails, useUpdateSubscription } from "@custom-hooks/billing";
import { useGetOfferFreeTrial } from "@custom-hooks/billing/hooks/useGetOfferFreeTrial";
import { useGetCurrentProjectId } from "@custom-hooks/projects";
import { AddonActionNames, AddonItem, AddonLookupKey, AddonsFunctionality } from "@data-types/billing-types";
import BoltIcon from "@layouts/svg-icon/bolt-icon.svg";
import { addons, FAILED_TO_UPGRADE_SUBSCRIPTION_ERROR_MESSAGE } from "@lib/billing/constants";
import { ContactDialog } from "@tw-components/common/ContactDialog";
import { UpgradingInfraDialog } from "@tw-components/common/UpgradingInfraDialog";
import { GenericDialog, Message, MessageType } from "@tw-components/ui/iso";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { AddonPriceDetails } from "../AddonPriceDetails";
import { ProPlanFreeTrialAlert } from "../ProPlanFreeTrialAlert";
import { SubscriptionStatusDialog } from "../SubscriptionStatusDialog";
import { PlanCard, PlanCardProps } from "./PlanCard";

/**
 * The props for the UpgradeDialog.
 *
 * @property {boolean} isOpen - Indicates whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 * @property {string} projectId - The project to upgrade.
 * @property {"databases" | "backups" | "project_card" | "storage_usage_warning"} triggerSource - Identifies where the upgrade dialog was triggered from.
 */
type UpgradeDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  triggerSource: "databases" | "backups" | "project_card" | "storage_usage_warning";
};

/**
 * Component for displaying
 *
 * @property {boolean} isOpen - Indicates whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 *
 * @returns {JSX.Element} The rendered `UpgradeDialog` component.
 */
export function UpgradeDialog({
  isOpen,
  onClose,
  projectId,
  triggerSource
}: UpgradeDialogProps): JSX.Element {
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState<Message | undefined>(undefined);
  const [showSubscriptionStatusDialog, setShowSubscriptionStatusDialog] = useState(false);
  const [showUpgradingInfraDialog, setShowUpgradingInfraDialog] = useState(false);
  const [furtherActionRequired, setFurtherActionRequired] = useState(false);
  const [invoiceHostedUrl, setInvoiceHostedUrl] = useState("");
  const currentProjectId = useGetCurrentProjectId();
  const router = useRouter();
  const posthog = usePostHog();
  const rowNames = [AddonsFunctionality.CLUSTER_NODE_COUNT, AddonsFunctionality.STORAGE_IN_GB, `${AddonsFunctionality.VCPU} / ${AddonsFunctionality.RAM}`, AddonsFunctionality.RETENTION_IN_HOURS, AddonsFunctionality.CONNECTIONS, AddonsFunctionality.SUPPORT, "Cost"];
  const currentPlanDetails = addons[AddonLookupKey.SANDBOX_PROJECT].functionalityDetails;
  const proPlanDetails = addons[AddonLookupKey.PRO_PROJECT].functionalityDetails;
  const currentPlanCardProps: PlanCardProps = {
    title: "Sandbox Project",
    details: [{
      ...currentPlanDetails.Cluster
    }, {
      ...currentPlanDetails.Storage
    }, {
      summary: `${currentPlanDetails.vCPUs?.summary} / ${currentPlanDetails.RAM?.summary}`
    }, {
      summary: "None"
    }, {
      ...currentPlanDetails.Connections
    }, {
      ...currentPlanDetails.Support
    }, {
      summary: <AddonPriceDetails addonKey={AddonLookupKey.SANDBOX_PROJECT} />
    }]
  };
  const proPlanCardProps: PlanCardProps = {
    title: "Pro Project",
    details: [{
      ...proPlanDetails.Cluster
    }, {
      ...proPlanDetails.Storage
    }, {
      summary: `${proPlanDetails.vCPUs?.summary} / ${proPlanDetails.RAM?.summary}`
    }, {
      ...proPlanDetails.Backups
    }, {
      ...proPlanDetails.Connections
    }, {
      ...proPlanDetails.Support
    }, {
      summary: <AddonPriceDetails addonKey={AddonLookupKey.PRO_PROJECT} />
    }]
  };
  const {
    hasSubscription,
    subscriptionStatusAlertProps
  } = useGetSubscriptionDetails();
  const offerFreeTrial = useGetOfferFreeTrial();
  const {
    updateSubscription,
    isUpdatingSubscription,
    updateSubscriptionError
  } = useUpdateSubscription({
    successCallback: updateSubscriptionResult => {
      switch (updateSubscriptionResult.value.status) {
        case "pending":
          setInvoiceHostedUrl(updateSubscriptionResult.value.redirectUrl);
          setFurtherActionRequired(true);
          setAlertMessage({
            type: MessageType.Error,
            title: "Further action is required on Stripe"
          });
          break;
        case "completed":
          onClose();
          if (currentProjectId) {
            router.push("/");
          }
          break;
      }
    },
    errorCallback: () => {
      setAlertMessage(FAILED_TO_UPGRADE_SUBSCRIPTION_ERROR_MESSAGE);
    }
  });
  const {
    createSubscription,
    isCreatingSubscription,
    createSubscriptionError
  } = useCreateSubscription({
    successCallback: () => {
      onClose();
    },
    errorCallback: () => {
      setAlertMessage(FAILED_TO_UPGRADE_SUBSCRIPTION_ERROR_MESSAGE);
    }
  });
  const redirectToStripe = () => {
    window.open(invoiceHostedUrl, "_blank");
    onClose();
  };
  const handleUpgrade = async () => {
    posthog.capture("upgrade_dialog_cta_button_clicked", {
      project_id: projectId,
      trigger_source: triggerSource,
      has_subscription: hasSubscription
    });
    const newAddon: AddonItem = {
      addon: AddonLookupKey.PRO_PROJECT,
      quantity: 1,
      action: {
        name: AddonActionNames.UPGRADE,
        data: {
          projectId
        }
      }
    };
    if (hasSubscription) {
      await updateSubscription({
        newAddons: [newAddon]
      });
    } else {
      await createSubscription({
        newAddons: [newAddon]
      });
    }
  };
  useEffect(() => {
    if (isOpen) {
      posthog.capture("upgrade_dialog_opened", {
        project_id: projectId,
        trigger_source: triggerSource,
        has_subscription: hasSubscription
      });
    }
  }, [isOpen]);
  return <>
      <GenericDialog title="Upgrade to SQLite Cloud Pro" description={"Upgrading to SQLite Cloud Pro will automatically turn your existing sandbox project into a pro project."} body={<div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-flex tw-flex-row">
              <div className="tw-flex tw-flex-col tw-justify-end tw-gap-5 tw-pb-4 tw-pr-2 sm:tw-min-w-28 sm:tw-pr-8">
                {rowNames.map(rowName => <div key={rowName} className="tw-text-12px-semiBold tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark">
                    {rowName}
                  </div>)}
              </div>

              <div className="tw-grid tw-w-full tw-grid-cols-2 tw-gap-4">
                <PlanCard {...currentPlanCardProps} />
                <PlanCard {...proPlanCardProps} classname="tw-bg-interface-innerCard-light dark:tw-bg-interface-innerCard-dark" />
              </div>
            </div>
            {offerFreeTrial && offerFreeTrial.isValid && <ProPlanFreeTrialAlert />}
          </div>} alertMessage={alertMessage} secondaryButtonProps={{
      text: <p>
              Need more? <span className="tw-underline">Contact us</span>
            </p>,
      onClick: () => {
        onClose();
        setShowContactDialog(true);
      }
    }} submitButtonProps={{
      variant: "primary",
      label: furtherActionRequired ? "Continue to Stripe" : "Upgrade to Pro",
      icon: <BoltIcon />,
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        if (furtherActionRequired) {
          redirectToStripe();
        } else {
          handleUpgrade();
        }
      },
      className: "tw-w-[22.188rem]",
      showLoader: isCreatingSubscription || isUpdatingSubscription
    }} onClose={onClose} afterLeave={() => {
      setFurtherActionRequired(false);
      setAlertMessage(undefined);
      setInvoiceHostedUrl("");
    }} beforeEnter={() => {
      if (process.env.NEXT_PUBLIC_UPGRADING_INFRA && process.env.NEXT_PUBLIC_UPGRADING_INFRA.toLowerCase() === "true") {
        onClose();
        setShowUpgradingInfraDialog(true);
      } else if (subscriptionStatusAlertProps) {
        onClose();
        setShowSubscriptionStatusDialog(true);
      }
    }} open={isOpen} size="xl" buttonLayoutType="column" data-sentry-element="GenericDialog" data-sentry-source-file="UpgradeDialog.tsx" />

      <ContactDialog isOpen={showContactDialog} onClose={() => setShowContactDialog(false)} error={createSubscriptionError || updateSubscriptionError} type={"billing"} source={"Upgrade Dialog"} data-sentry-element="ContactDialog" data-sentry-source-file="UpgradeDialog.tsx" />

      {subscriptionStatusAlertProps && <SubscriptionStatusDialog {...subscriptionStatusAlertProps} isOpen={showSubscriptionStatusDialog} onClose={() => setShowSubscriptionStatusDialog(false)} />}

      <UpgradingInfraDialog isOpen={showUpgradingInfraDialog} onClose={() => setShowUpgradingInfraDialog(false)} type={"upgrade_to_pro"} data-sentry-element="UpgradingInfraDialog" data-sentry-source-file="UpgradeDialog.tsx" />
    </>;
}