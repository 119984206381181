//
// withPermissionsGuard.ts
//

import { useUserPermissions } from "@custom-hooks/role-based-access-control";
import { UserPermissions } from "@data-types/role-based-access-control-types";
import { useRouter } from "next/router";
import { useEffect } from "react";

/**
 * HOC to enforce multiple permissions for accessing a component.
 *
 * @param Component - The component to protect.
 * @param permissions - An array of permissions that all must be `true` for access.
 */
export function withPermissionsGuard<T extends object>({
  Component,
  permissions,
  NoPermissionComponent,
  redirectIfUnauthorized
}: {
  Component: React.ComponentType<T>;
  permissions: (keyof UserPermissions)[];
} & ({
  NoPermissionComponent?: React.ReactNode;
  redirectIfUnauthorized?: never;
} | {
  NoPermissionComponent?: never;
  redirectIfUnauthorized?: boolean;
})) {
  return function ProtectedComponent(props: T): JSX.Element | null {
    const userPermissions = useUserPermissions();
    const router = useRouter();
    const hasAllPermissions = permissions.every(perm => userPermissions[perm]);
    useEffect(() => {
      if (!hasAllPermissions && redirectIfUnauthorized) {
        router.replace(`/missing-permissions`);
      }
    }, [hasAllPermissions, redirectIfUnauthorized, router]);
    if (!hasAllPermissions) {
      if (NoPermissionComponent) {
        return <>{NoPermissionComponent}</>;
      } else {
        return null;
      }
    }
    return <Component {...props} />;
  };
}