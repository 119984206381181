import { useGetSubscriptionDetails } from "@custom-hooks/billing";
import * as Headless from "@headlessui/react";
import { withPermissionsGuard } from "@hoc/withPermissionsGuard";
import RecycleIcon from "@layouts/svg-icon/recycle-icon.svg";

/**
 * A button that allows the user to open the Delete Organization Dialog.
 *
 * @param {React.ComponentProps<typeof Headless.Button>} props - The props for the button component.
 *
 * @returns {JSX.Element} The JSX code for rendering the button.
 */
function DeleteOrganizationButton(props: React.ComponentProps<typeof Headless.Button>): JSX.Element {
  return <Headless.Button className="tw-text-semantics-error-light dark:tw-text-semantics-error-dark" {...props} data-sentry-element="unknown" data-sentry-component="DeleteOrganizationButton" data-sentry-source-file="DeleteOrganizationButtonWithPermissionsGuard.tsx">
      <RecycleIcon data-sentry-element="RecycleIcon" data-sentry-source-file="DeleteOrganizationButtonWithPermissionsGuard.tsx" />
    </Headless.Button>;
}

/**
 * A higher-order component that wraps the DeleteOrganizationButton with permission checks.
 * If the user lacks the required permissions, the button is not rendered.
 *
 * @param {React.ComponentProps<typeof Headless.Button>} props - The props for the button component.
 *
 * @returns {JSX.Element} The JSX code for rendering the button with permissions guard.
 */
export const DeleteOrganizationButtonWithPermissionsGuard = (props: React.ComponentProps<typeof Headless.Button>) => {
  const {
    hasSubscription
  } = useGetSubscriptionDetails();
  const WithPermissionGuard = withPermissionsGuard({
    Component: DeleteOrganizationButton,
    permissions: hasSubscription ? ["canDeleteOrganization", "canDeleteSubscription"] : ["canDeleteOrganization"]
  });
  return <WithPermissionGuard {...props} data-sentry-element="WithPermissionGuard" data-sentry-component="DeleteOrganizationButtonWithPermissionsGuard" data-sentry-source-file="DeleteOrganizationButtonWithPermissionsGuard.tsx" />;
};