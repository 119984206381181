import { useGetCurrentOrganizationId, useGetOrganization, useGetUserOrganizations } from "@custom-hooks/organizations";
import { useGetCurrentProjectId, useGetProject, useGetUserProjectsByOrganizationId } from "@custom-hooks/projects";
import { useCustomRouter } from "@custom-hooks/useCustomRouter";
import { useGetUserSession } from "@custom-hooks/user";
import BreadcrumbSeparator from "@layouts/svg-icon/breadcrumb-separator.svg";
import DownChevronIcon from "@layouts/svg-icon/down-chevron-icon.svg";
import PlusIcon from "@layouts/svg-icon/plus-icon.svg";
import { CreateOrganizationDialog } from "@tw-components/organizations/CreateOrganizationDialog";
import { DeleteOrganizationButtonWithPermissionsGuard } from "@tw-components/organizations/DeleteOrganizationButtonWithPermissionsGuard";
import { DeleteOrganizationDialogWithGuard } from "@tw-components/organizations/DeleteOrganizationDialogWithGuard";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu, NavbarItem } from "@tw-components/ui/iso";
import clsx from "clsx";
import { useState } from "react";
export function BreadcrumbNavigation(): JSX.Element {
  const [showCreateOrganizationDialog, setShowCreateOrganizationDialog] = useState(false);
  const [selectedOrganizationToDelete, setSelectedOrganizationToDelete] = useState<string | undefined>(undefined);
  const projectId = useGetCurrentProjectId();
  const organizationId = useGetCurrentOrganizationId();
  const {
    data: project
  } = useGetProject(projectId || "");
  const {
    data: projects
  } = useGetUserProjectsByOrganizationId(organizationId || "");
  const organization = useGetOrganization(organizationId || "");
  const organizations = useGetUserOrganizations();
  const {
    router,
    pathname
  } = useCustomRouter();
  const onOrganizationSelected = (newOrganizationId: string) => {
    router?.push(`/organizations/${newOrganizationId}/projects`);
  };
  const onProjectSelected = (newProjectId: string) => {
    router?.push(`/organizations/${organizationId}/projects/${newProjectId}/${pathname?.split("/").at(-1) ?? "databases"}`);
  };
  return organization ? <>
      <div className="tw-flex tw-flex-row tw-items-center">
        <Dropdown>
          <DropdownButton as={NavbarItem}>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-14px-regular tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark">
              {organization.name}
              <DownChevronIcon />
            </div>
          </DropdownButton>
          <DropdownMenu>
            {organizations?.map(organization => <>
                <DropdownItem key={organization.organizationId} onClick={() => onOrganizationSelected(organization.organizationId)} className={organization.organizationId === organizationId ? "tw-bg-brandBlues-ice-light dark:tw-bg-brandBlues-ice-dark" : ""}>
                  <DropdownLabel className={clsx("tw-flex tw-w-full tw-flex-1 tw-items-center tw-justify-between tw-gap-4", organization.organizationId === organizationId ? "tw-text-brandBlues-brandDark-light dark:tw-text-brandBlues-brandDark-dark" : "")}>
                    {organization.name}

                    <DeleteOrganizationButtonWithPermissionsGuard onClick={(event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setSelectedOrganizationToDelete(organization.organizationId);
                }} />
                  </DropdownLabel>
                </DropdownItem>
              </>)}

            <DropdownItem onClick={() => setShowCreateOrganizationDialog(true)}>
              <DropdownLabel className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-brandBlues-brandDark-light dark:tw-text-brandBlues-brandDark-dark">
                <PlusIcon />
                Add Workspace
              </DropdownLabel>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        {project?.name && <>
            <BreadcrumbSeparator />

            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-14px-regular tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark">
                  {project.name}
                  <DownChevronIcon />
                </div>
              </DropdownButton>
              <DropdownMenu>
                {projects?.map(project => <DropdownItem key={project.id} onClick={() => onProjectSelected(project.id)} className={project.id === projectId ? "tw-bg-brandBlues-ice-light dark:tw-bg-brandBlues-ice-dark" : ""}>
                    <DropdownLabel className={project.id === projectId ? "tw-text-brandBlues-brandDark-light dark:tw-text-brandBlues-brandDark-dark" : ""}>
                      {project.name}
                    </DropdownLabel>
                  </DropdownItem>)}
              </DropdownMenu>
            </Dropdown>
          </>}
      </div>

      <DeleteOrganizationDialogWithGuard organizationId={organization.organizationId} isOpen={selectedOrganizationToDelete === organization.organizationId} onClose={() => setSelectedOrganizationToDelete(undefined)} />

      <CreateOrganizationDialog isOpen={showCreateOrganizationDialog} onClose={() => setShowCreateOrganizationDialog(false)} />
    </> : <></>;
}