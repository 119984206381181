import { useCancelSubscription, useGetSubscriptionDetails } from "@custom-hooks/billing";
import { useGetCurrentOrganizationId, useGetOrganization } from "@custom-hooks/organizations";
import { useDeleteOrganization } from "@custom-hooks/organizations/hooks/useDeleteOrganization";
import { useGetUserSession } from "@custom-hooks/user";
import { withPermissionsGuard } from "@hoc/withPermissionsGuard";
import { GenericDeleteDialog, MessageType } from "@tw-components/ui/iso";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";

/**
 * Props for the DeleteOrganizationDialog component.
 *
 * @property {boolean} isOpen - Indicates whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 * @property {string} organizationId - The ID of the organization to be deleted.
 */
type DeleteOrganizationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  organizationId: string;
};

/**
 * A dialog component for deleting an organization (workspace).
 *
 * This component prompts users with a confirmation dialog before deleting a workspace.
 * It ensures the workspace ID is available and triggers deletion upon confirmation.
 *
 * @param {DeleteOrganizationDialogProps} props - Component props.
 *
 * @returns {JSX.Element} The DeleteOrganizationDialog component.
 */
function DeleteOrganizationDialog({
  isOpen,
  onClose,
  organizationId
}: DeleteOrganizationDialogProps): JSX.Element {
  const {
    data: session
  } = useGetUserSession();
  const {
    deleteOrganization
  } = useDeleteOrganization({});
  const {
    cancelSubscription
  } = useCancelSubscription({});
  const currentOrganizationId = useGetCurrentOrganizationId();
  const organization = useGetOrganization(organizationId || "");
  const {
    hasSubscription,
    subscriptionStatus,
    trialEnd
  } = useGetSubscriptionDetails();
  const router = useRouter();
  const posthog = usePostHog();
  return <>
      {organization && organization.ownerId === session?.userId && <GenericDeleteDialog open={isOpen} onClose={onClose} title={"Delete Workspace?"} description={"Deleting this workspace will remove all projects (Pro and Sandbox), invited users, and deactivate any linked subscriptions. This action is permanent and cannot be undone by you or other users. "} deleteButton={{
      onClick: async setStatus => {
        if (hasSubscription === undefined || !organizationId) {
          setStatus({
            type: MessageType.Error,
            title: "Failed to delete workspace",
            description: "An error occurred while deleting your workspace. Please try again later. If the issue persists, contact us for assistance."
          });
        } else {
          if (hasSubscription) {
            const cancelSubscriptionResult = await cancelSubscription({
              deleteOrganization: true
            });
            if (cancelSubscriptionResult.success) {
              onClose();
            } else {
              setStatus({
                type: MessageType.Error,
                title: "Failed to delete workspace",
                description: "An error occurred while deleting your workspace. Please try again later. If the issue persists, contact us for assistance."
              });
            }
          } else {
            const deleteOrganizationResult = await deleteOrganization({
              organizationId
            });
            if (deleteOrganizationResult.success) {
              onClose();
              posthog.capture("organization_deleted", {
                organization_id: organizationId
              });
              if (organizationId === currentOrganizationId) {
                router.push("/");
              }
            } else {
              setStatus({
                type: MessageType.Error,
                title: "Failed to delete workspace",
                description: "An error occurred while deleting your workspace. Please try again later. If the issue persists, contact us for assistance."
              });
            }
          }
        }
      },
      label: "Delete"
    }} variant={{
      type: "with_confirmation",
      firstConfirmation: {
        label: `To verify, type “${organization?.name}” below to delete this workspace for everyone`,
        text: organization?.name ?? "",
        name: "Workspace name"
      },
      secondConfirmationText: "delete workspace"
    }} />}
    </>;
}

/**
 * A higher-order component that wraps the DeleteOrganizationDialog with permission checks.
 * If the user lacks the required permissions, the dialog is not rendered.
 *
 * @param {DeleteOrganizationDialogProps} props - The props for the button component.
 *
 * @returns {JSX.Element} The JSX code for rendering the dialog with permissions guard.
 */
export const DeleteOrganizationDialogWithGuard = (props: DeleteOrganizationDialogProps) => {
  const {
    hasSubscription
  } = useGetSubscriptionDetails();
  const WithPermissionGuard = withPermissionsGuard({
    Component: DeleteOrganizationDialog,
    permissions: hasSubscription ? ["canDeleteOrganization", "canDeleteSubscription"] : ["canDeleteOrganization"]
  });
  return <WithPermissionGuard {...props} data-sentry-element="WithPermissionGuard" data-sentry-component="DeleteOrganizationDialogWithGuard" data-sentry-source-file="DeleteOrganizationDialogWithGuard.tsx" />;
};